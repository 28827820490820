import React, { useState, useEffect } from 'react';

function App() {
  const [urls, setUrls] = useState([]);
  const fetchPost = async () => {
    const response = await fetch('https://hack13.link/metrics', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-API-Key': 'aeb91e7c-5602-4d4c-9414-79b341d07696'
      }
    });
    const data = await response.json();
      setUrls(data);
  };

  useEffect(() => {
    fetchPost();
  }, []);


  return (
    <div className="App">
      <table>
        <tr>
          <th>ShortURL</th>
          <th>Visits</th>
          <th>Long URL</th>
        </tr>
        {urls && 
          urls.map((short) => (
            <tr key={short.slug}>
              <td>https://hack13.link/{short.slug}</td>
              <td>{short.count}</td>
              <td><a href={short.longURL} target="_blank" rel="noopener">{short.longURL}</a></td>
            </tr>
          ))
        }
        </table>
      <button onClick={fetchPost}>Fetch Update</button>
    </div>
  );
}

export default App;
